import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import {seismicTheme, Theme} from '@seismic/mantle'
import { VisualizationsTheme, seismicVisualizationsTheme } from '@seismic/visualizations';
import {Web} from "./pages/Web";
import "./App.css";

export default function App() {
    
    return (
        <>
            <Theme theme={seismicTheme({useGlobal: true, defaultPortalContainerSelector: '.mntl-portals'})}>
                <VisualizationsTheme theme={seismicVisualizationsTheme({ useGlobal: true })}>
                <Router>
                    <Routes>
                        <Route exact path={"/*"} element={<Web/>}/>
                    </Routes>
                </Router>
                <div className='mntl-portals'/>
                </VisualizationsTheme>
            </Theme>
 
        </>
    );
}
