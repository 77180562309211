import React, {useEffect} from "react";
import {useNavigate, Routes, Route, Navigate} from "react-router-dom";
import {IllustrationAllDoneWithWork, IllustrationNoAccess, neutralColors} from "@seismic/mantle";

const Header = () => {

    const navigate = useNavigate();
    
    return <div style={{backgroundColor: neutralColors.white, height: "56px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "8px", borderBottom: `1px solid ${neutralColors.silver}`}}>
        <div style={{color: "#122A7E", display: "flex", justifyContent: "space-between", alignItems: "center", }}>
            <img height={16} width={16} src="/favicon.ico" alt="icon" /> <span onClick={()=>navigate("/")} style={{paddingLeft: "8px", fontSize: "24px", fontWeight: 400}}>Seismic for Viva Sales</span>
        </div>
    </div>
}

const Logos = () => {
    return <div style={{display: "flex", justifyContent: "center", padding: "16px", gap: "16px", alignItems: "center"}}>
        <img alt="Seismic Logo" style={{border: `1px solid ${neutralColors.silver}`, height: "50px", width: "50px"}} src="seismic_logo.png" />
        <div style={{fontSize: "24px", fontColor: neutralColors.charcoal}}>+</div>
        <img alt="Microsoft Logo" style={{height: "50px", width: "50px"}} src="https://www.microsoft.com/favicon.ico" />
    </div>
}

const CenteredStuff = ({children}) => {
    return <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "80vh"}}>
        <div style={{backgroundColor: "white", padding: "16px", borderRadius: "8px", border: "1px solid #cfd4da", textAlign: "center"}}>
            {children}            
        </div>
    </div>   
}

const Stuff = () => {

    const params = Object.fromEntries(new URLSearchParams(location.search));
    
    if(params.error) {
        return <CenteredStuff>
            <div>
                <IllustrationNoAccess height={200} />
            </div>
            <div>Could not connect the Seismic tenant <b>{params.state}</b> to a Microsoft organization.</div>
            <div style={{padding: "24px"}}>
                <div>{params.error}</div>
                <div><a href={params.error_uri}>{params.error_description}</a></div>
            </div>
        </CenteredStuff>
    }
    
    return <CenteredStuff>
        <div>
            <IllustrationAllDoneWithWork height={200} />
        </div>
        <div style={{width: "400px"}}>You have successfully connected the Microsoft organization <b>{params.tenant}</b> to the Seismic tenant <b>{params.state}</b></div>
    </CenteredStuff>
}

export const Web = () => {
    
    return <>
        <Header />
        <div style={{padding: "16px"}}>
            <Routes>
                <Route path="/redirect/*" element={<Stuff />} />
                <Route path="*" element={<Navigate to="/redirect" replace />} />
            </Routes>
        </div>
    </>
}

